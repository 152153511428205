<template>
	<div class="page container">
		<v2-back-btn :route="`/teaching`" />
		<div class="page__info">
			<div class="page__info--title">
				Students
			</div>
		</div>

		<table-view
			:table="getTable"
			:showSearch="false"
			:pagination="pagination"
			:totalItems="totalItems"
			@paginate="onPaginate"
		/>

	</div>
</template>

<script>

	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import TableView     from '@/components/ui/views/tableView/TableView';
	import api           from '@/services/api';
	import dayjs         from 'dayjs';
	import relativeTime  from 'dayjs/plugin/relativeTime';

	dayjs.extend(relativeTime);

	export default {
		layout: 'v2default',
		metaInfo: {
			title: 'Students'
		},
		components: {
			V2BackBtn,
			TableView
		},
		data: () => ({
			students: [],
			pagination: {
				perPage: 			20,
				currentPage: 	1,
				totalPages: 	0
			},
			totalItems:     0
		}),
		computed: {
			getTable () {
				const headings = [
					'Name',
					'Number of courses'
				];
				if (!this.getStudents) {
					return {
						headings
					};
				}
				return {
					headings,
					rows: this.getStudents.map((student) => {
						return {
							route: `/teaching/students/${student.id}`,
							columns: [
								this.getNameString(student),
								student.numCourses
							]
						};
					})
				};
			},
			getStudents () {
				if (!this.students ||
						!this.students.length) {
					return false;
				}
				return this.students;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/students',
						text: 'Students'
					}
				];
			}
		},
		created: function () {
			this.setStudents();
		},
		methods: {
			onPaginate (value) {
				this.pagination.currentPage = value;
				this.setStudents();
			},
			getNameString (student) {
				if (!student) {
					return false;
				}
				if (student?.name) {
					return student.name;
				}
				return `${student.firstName} ${student.lastName}`;
			},
			async setStudents () {
				const response = await api.teaching.getAllStudents({
					include: 'metadata',
					page: this.pagination.currentPage,
					per: this.pagination.perPage
				});
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.students = response.results;
				this.pagination.totalPages = response.meta.numPages;
				this.pagination.currentPage = response.meta.page;
				this.totalItems = response.meta.totalItems;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
